/* Custom Knockout binding: spinner 
 *  documentations: https://spin.js.org/
 *  github: https://github.com/fgnass/spin.js
 *  
 *  A simple example of adding a spinner:
 *  <div class="spin search-rooms-spin" data-bind="spinner: { color: '#333', lines: 11, length: 19, width: 10, radius: 22, corners: 1, trail: 58 }"></div>
*/

import * as Spinner from 'spin';

ko.bindingHandlers.spinner = {
    init: function (element, valueAccessor, allBindingsAccessor) {

        var options = valueAccessor() || { color: "#36719c", lines: 11, length: 19, width: 10, radius: 22, corners: 1, trail: 58 };

        var spinner = new Spinner(options).spin(element);

        ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
            spinner.stop();
            spinner = null;
        });

    },
    update: function (element, valueAccessor, allBindingsAccessor) {

    }
};