import 'appstart';
//import 'SCSSEntries/hideawaysFrontPage.scss';
import 'Vendors/knockout/knockout-pack';
//import 'Utils/formatters';
import 'SrcJS/knockout-extensions/knockout.spinner';
import 'Utils/mere.js';
//import 'Utils/slider.js'; //??? //2021-06-14 Disabled
//import { InitializeMapDestinations } from 'SrcJS/maps/mapbox-custom';
//
//
//InitializeMapDestinations();
import(/* webpackChunkName: "widgets-module" */ 'Widgets/widgetMoreItems.js');
import(/* webpackChunkName: "searchbar-module" */ 'searchbar');
import(/* webpackChunkName: "sections-module" */ 'Modules/sections/destinationIntro.js');
import(/* webpackChunkName: "sections-module" */ 'Modules/sections/banner-cards.js');
import(/* webpackChunkName: "sections-module" */ 'Modules/sections/destinationsSlider.js');
import(/* webpackChunkName: "sections-module" */ 'Modules/sections/frontpageHotelThemes.js');
import(/* webpackChunkName: "sections-module" */ 'Modules/sections/mapWithDestinations.js');
import(/* webpackChunkName: "sections-module" */ 'Modules/sections/hotelListWithPrices.js');
import(/* webpackChunkName: "sections-module" */ 'Modules/sections/hotelList.js');


//AppLoader.setReady();